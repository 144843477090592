.calendar-wrapper {
  position: fixed;
  top: $header-height;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1001;
  display: none;
  opacity: 0;
}

.calendar {
}

.calendar-dates {
  white-space: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  padding-left: 7px;

  padding-left: ($gutter-width*2);
  padding-right: ($gutter-width*2);
  padding-top: $gutter-width;
  padding-bottom: $gutter-width;
  background-color: #fff;

  @include tablet-portrait-and-below {
    padding-left: ($gutter-width-mobile*2);
    padding-right: ($gutter-width-mobile*2);
    padding-top: $gutter-width-mobile;
    padding-bottom: $gutter-width-mobile;
  }

  .month-group {
    position: relative;
    display: inline-block;
    padding-top: $gutter-width*1.5;

    &:first-of-type {
      .month {
        color: $color-teal;
      }
    }
  }

  .month {
    display: inline-block;
    position: absolute;
    top: 0;
    left: -4px;
    @include small-caps;
  }

  .day {
    display: inline-block;
    margin-right: -6px;
    margin-left: -6px;
    width: 44px;

    .number {
      display: block;
      background-image: url("images/day-marker.svg");
      width: 100%;
      height: 45px;
      line-height: 45px;
      color: #fff;
      text-align: center;
    }

    .name {
      display: block;
      width: 100%;
      text-transform: uppercase;
      color: $color-gray;
      text-align: center;
      text-indent: 14px;
    }

    &:hover {
      .number {
        color: $color-teal;
      }
    }

    &.active {
      .number {
        background-image: url("images/day-marker-active.svg");
      }
    }

    &.selected {
      .number {
        background-image: url("images/day-marker-selected.svg");
        color: #fff;
      }
    }
  }
}

.calendar-events {
  display: none;

  padding-left: ($gutter-width*2);
  padding-right: ($gutter-width*2);
  padding-top: 0;
  padding-bottom: $gutter-width;
  background-color: #fff;

  @include tablet-portrait-and-below {
    padding-left: ($gutter-width-mobile);
    padding-right: ($gutter-width-mobile);
    padding-top: ($gutter-width-mobile);
    padding-bottom: ($gutter-width-mobile);
  }

  .event {
    display: inline-block;
    margin-right: $gutter-width*2;

    @include tablet-portrait-and-below {
      margin-right: 0;
      display: block;
      margin-bottom: ($gutter-width-mobile);
      &:last-child {
        margin-bottom: 0;
      }
    }

    .time {
      a {
        color: $color-teal;
      }
    }

    .category {
      @include small-caps;
    }

    .title {
      @extend h1;
    }

    .actions {
      a {
        @include small-caps;
        color: $color-gray;
        &.primary {
          color: $color-pink;
        }
      }
    }
  }
}
