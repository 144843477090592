ul.file-list {

	li {

		a {
			display: block;
			color: $color-pink;
		}

		span {
			@include small-caps;
			color: $color-gray;
		}

	}

}