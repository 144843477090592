// --------------------------  colors -------------------------- //

$color-main: #000000;

$color-pink: #de465a;
$color-gray: #d1d1d1;
$color-green: #013035;
$color-blue: #1b365d;
$color-teal: #00aea9;

// --------------------------  typography -------------------------- //

$base-line-height: 1.65;

// --------------------------  settings -------------------------- //

$default-duration: 0.24s;
$default-easing: $ease-out-quint;

// --------------------------  dimensions -------------------------- //

$gutter-width: 20px;
$gutter-width-mobile: 15px;

$grid-width: 1400px;

$footer-height: 300px;
$footer-height-mobile: 130px;
$columns: 3;

$logo-width-desktop: 63px;
$logo-height-desktop: 76px;

$header-height: 125px;
$header-height-minimised: 60px;

$header-height-mobile: 210px;
$header-height-mobile-closed: 75px;
$header-height-mobile-minimised: 45px;

$nav-secondary-height: 60px;
$nav-secondary-height-mobile: 40px;

$logo-width-mobile: 37px;
$logo-height-mobile: 45px;

$info-bar-height: 125px;

$headline-height: 7.5vh;
