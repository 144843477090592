@keyframes bounce {
	0%, 25%, 50%, 75%, 100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(-8px);
	}
	60% {
		transform: translateY(-3px);
	}
}


.page-headline-wrapper {
	@extend %row;
}

.page-headline {
	@extend h1;
	display: block;
	width: 100%;
	height: 100%;
	text-align: center;
	color: $color-green;

	a {
		color: $color-green;
	}

	span {
		color: inherit;
	}

	em {
		font-style: normal;
		color: $color-gray;
	}

	@include tablet-portrait-and-below {
		height: 40px;
		line-height: 40px;
	}

	@include mobile-landscape-and-below {
		padding: $gutter-width-mobile $gutter-width-mobile/2;
		text-align: left;
		height: auto;
		line-height: 1.35;
	}

	@include above-tablet-portrait {
		height: $headline-height;
		line-height: $headline-height;
	}

	&.with-arrow-down {
		&:after {
			content: "";
			animation: bounce 2s infinite;
			margin-left: $gutter-width/2;
			@extend .icon;
			@extend .icon-arrow-down;

			@include mobile-landscape-and-below {
				clear: both;
				display: block !important;
				margin-left: 0;
				margin-top: 10px;
			}

		}

	}


}
