.page-row {
	background-color: #cccccc;
	overflow: hidden;
	padding-top: $gutter-width;
	padding-bottom: $gutter-width;

	&.with-additional-padding-bottom {
		padding-bottom: $gutter-width*3;
	}

	> .heading {
		margin-bottom: $gutter-width;
		padding-left: $gutter-width;
		padding-right: $gutter-width;
	}

}