@include tablet-portrait-and-below {
  // --------------------------  header  -------------------------- //

  header.header {
    height: $header-height-mobile;
    transform: translateY(
       ($header-height-mobile - $header-height-mobile-closed) * -1
    );

    &.visibility-unpinned {
      transform: translateY(
         ($header-height-mobile - $header-height-mobile-minimised) *-1
      );
    }

    &.active {
      transform: translateY(0);
    }
  }

  // --------------------------  logo  -------------------------- //

  header.header {
    .header-logo-wrapper {
      width: round($logo-width-mobile + ($gutter-width-mobile*2));
      background-size: 61px $header-height-mobile;
    }

    &.visibility-unpinned:not(.active) {
      .header-logo-wrapper {
        display: none;
      }
    }
  }

  a.header-logo {
    width: $logo-width-mobile;
    height: $logo-height-mobile;
    left: ($gutter-width-mobile);
    bottom: $gutter-width-mobile;
  }

  // --------------------------  logotype  -------------------------- //

  .header-logotype-wrapper {
    display: none;
  }

  // --------------------------  calendar toggle  -------------------------- //

  header.header {
    a.calendar-toggle {
      bottom: $gutter-width-mobile;
      left: $logo-width-mobile + ($gutter-width*2.5);
    }

    &.visibility-unpinned:not(.active) {
      a.calendar-toggle {
        left: $gutter-width-mobile;
      }
    }
  }

  .header {
    li.search,
    li.newsletter {
      display: none;
    }
  }
}
