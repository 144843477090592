@include tablet-portrait-and-below {

	// --------------------------  primary navigation toggle  -------------------------- //

	button.navigation-toggle {
		display: block;
	}

	// --------------------------  primary navigation  -------------------------- //

	nav.primary {
		@include small-caps;
		width: 75%;
		padding-top: $gutter-width-mobile;
		left: $logo-width-mobile + ($gutter-width*2.5);

		ul {
			opacity: 0;
			transition: opacity $default-duration $default-easing;
			position: relative;
			z-index: 1000;

			header.active & {
				opacity: 1;
			}

			li {
				display: block;
				position: relative;
				line-height: 21px;

				// Need to hide both newsletter and search for mobile, not enough space
				&.navigation-search,
				&.navigation-newsletter {
					display: none;
				}

				&.social {
					float: left;

					display: none;

					&:first-child {

					}

					a {

					}

				}

			}

		}

	}

	// --------------------------  secondary navigation  -------------------------- //

	header.header {

		nav.secondary {
			top: $header-height-mobile-closed;
			z-index: 1000;
			height: $nav-secondary-height-mobile;
			line-height: $nav-secondary-height-mobile;
			opacity: 1;
			transform-style: preserve-3d;
			transition: all $default-duration $default-easing;

			ul {
				background-color: #fff;
				position: absolute;
				width: 100%;
				text-align: center;

				li {
					display: inline-block;
					margin-right: $gutter-width-mobile/2;
					margin-left: $gutter-width-mobile/2;
				}

			}

		}

		&.visibility-unpinned {

			nav.secondary {
				top: $header-height-minimised;
				opacity: 0;
			}

		}

	}

}
