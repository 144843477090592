// -------------------------- template -------------------------- //

.spektrix-embed {
  background-color: rgba($color-teal, 0.95);

  .content {
    height: 100%;
    @include above-tablet-portrait {
      padding-top: $gutter-width*2;
      padding-bottom: $gutter-width;

      padding-left: $gutter-width*2;
      padding-right: $gutter-width*2;
    }

    @include tablet-portrait-and-below {
      padding-top: $gutter-width-mobile*2;
      padding-bottom: $gutter-width-mobile;

      padding-left: $gutter-width-mobile;
      padding-right: $gutter-width-mobile;
    }
  }
}

.spektrix-overlay {
  padding-top: 0 !important;
  @include overlay;

  iframe {
    background: none;
    display: block;
    width: 100%;
    overflow: hidden;
  }

  .heading {
    font-size: 38px;
    display: none;
  }

  .content {
    max-width: 890px;
    margin: 0 auto;
    width: 100%;
    height: calc(100% + 1px);
    position: relative;
    z-index: 1;

    @include above-tablet-portrait {
      padding-top: $gutter-width*2;
      padding-bottom: $gutter-width*2;

      padding-left: $gutter-width;
      padding-right: $gutter-width*4;

      //left: $gutter-width*2;
      //right: $gutter-width*2;
      //top: 0;
      //bottom: 0;
    }

    @include tablet-portrait-and-below {
      padding-top: $gutter-width-mobile*2;
      padding-bottom: $gutter-width-mobile*2;

      padding-left: $gutter-width-mobile;
      padding-right: $gutter-width-mobile;

      //top: 0;
      //bottom: 0;
      //left: 0;
      //right: 0;
    }
  }
}
