// --------------------------  skip links  -------------------------- //

#skiplinks {
	position: absolute;
	top: -999em;
	left: 0;
	right: 0;
	z-index: 1;
	width: 100%;
	background-color: #fff;
	text-align: center;
	padding: $gutter-width;
	@include small-caps;

	li {
		display: inline;
		margin-left: ($gutter-width/2);
		margin-right: ($gutter-width/2);

		a {

		}

	}

	&:focus {
		top: 0;
	}

}
