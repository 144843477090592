

.hero-slider-wrapper {
	width: 100%;
	background-color: #efefef;
	position: relative;
	overflow: hidden;

	background-image: url('images/spinner.svg');
	background-position: center;
	background-repeat: no-repeat;

	&.with-info-bar {
		padding-bottom: $info-bar-height;
	}

	@include tablet-landscape-and-up {
		height: (100vh - $headline-height);

		&.with-action-bar {
			height: (100vh - $headline-height - $headline-height);
		}

	}

	@include tablet-landscape-and-below {
		height: (100vh - $headline-height);

		&.with-action-bar {
			height: (100vh - $headline-height - $headline-height - $headline-height);
		}
	}

}

.hero-slider-cells {
	width: 100%;
	height: 100%;

	.cell {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
	}

}

// Fade in cells once the image has been loaded

html.js {

	.hero-slider-cells {

		.cell {
			opacity: 0;
			transition: opacity $default-duration*2 $default-easing;

			&.flickity-bg-lazyloaded {
				opacity: 1;
			}
		}

	}

}