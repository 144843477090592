.posts-wrapper {
  margin-bottom: $gutter-width;
}

.post-wrapper {
  overflow: hidden;
  width: 100%;
  border-bottom: 1px solid $color-pink;

  @include above-tablet-portrait {
    padding-top: ($gutter-width * 2);
    padding-bottom: ($gutter-width * 2);
  }

  @include tablet-portrait-and-below {
    padding-top: ($gutter-width);
    padding-bottom: ($gutter-width * 2);
  }
}

.post {
  position: relative;
  max-width: $grid-width;
  margin: 0 auto;
  width: 100%;
}

.post-media,
.post-body {
  @include above-tablet-portrait {
    width: 50%;
  }
}

.post-header {
  display: flex;

  @include above-tablet-portrait {
    padding-top: $gutter-width;
    padding-bottom: $gutter-width;
  }

  @include tablet-portrait-and-below {
  }

  .heading {
    color: $color-teal;
    padding-left: $gutter-width;
    padding-right: $gutter-width;

    @include above-tablet-portrait {
      width: 50%;
      margin-left: auto;
    }

    @include tablet-portrait-and-below {
      width: 100%;
    }

    a {
      color: $color-teal;
    }
  }

  @include tablet-portrait-and-below {
    margin-bottom: $gutter-width;
  }
}

.post-media {
  padding-left: $gutter-width;

  .item {
    margin-bottom: $gutter-width;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .item.image {
    img {
      display: block;
      border: 0;
    }
  }

  .item.video {
    position: relative;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
    padding-bottom: 56.23%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      background: transparent;
    }
  }

  @include above-tablet-portrait {
  }

  @include tablet-portrait-and-below {
    padding-right: $gutter-width;
    margin-bottom: $gutter-width;
  }
}

.post-content {
  display: flex;

  @include above-tablet-portrait {
    flex-direction: row;
  }

  @include tablet-portrait-and-below {
    flex-direction: column;
  }
}

.post-media,
.post-body {
  flex-shrink: 0;
  flex-grow: 0;
}

.post-header,
.post-body {
  @include body-text;
}

.post-body {
  @include above-tablet-portrait {
    top: 0;
    right: 0;
    padding-left: $gutter-width;
    padding-right: $gutter-width * 3;
  }

  @include tablet-portrait-and-below {
    padding-left: $gutter-width;
    padding-right: $gutter-width;
  }
}

#infscr-loading {
  width: 100%;
  max-width: 380px;
  padding: $gutter-width * 2;
  margin: 0 auto;

  img {
    width: $gutter-width;
    height: $gutter-width;
    display: block;
    float: left;
  }

  div {
    height: $gutter-width;
    line-height: $gutter-width;
    float: left;
    padding-left: $gutter-width;
    text-align: center;
  }
}
