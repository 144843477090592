// --------------------------  selection  -------------------------- //

::selection {
	background: #b3d4fc;
	text-shadow: none;
}


// --------------------------  global typography  -------------------------- //

body {
	font-feature-settings: "liga" 1, "dlig" 1, "kern" 1, "onum" 1, "tnum" 1, "frac" 1;
	backface-visibility: hidden;
	text-rendering: optimizeLegibility;
	@include antialiased;

	font-size: 14px;
	line-height: $base-line-height;

	font-family: "franklin-gothic-urw", $helvetica;
	font-style: normal;
	font-weight: 700;

	@include tablet-portrait-and-below {
		font-size: 12px;
	}

}

em {
	font-style: italic;
}

strong {
	font-style: normal;
	font-weight: 900;
}

a {
	text-decoration: none;
	color: $color-main;
	transition: color $default-duration/2 $default-easing, border-color $default-duration/2 $default-easing;
}

p {
	margin: 0;
	margin-bottom: 20px;

	a {

	}

	&:last-child {
		margin-bottom: 0;
	}

	strong {
		font-weight: 900;
	}

}

ul,ol {
	list-style: none;
	padding: 0;
	margin: 0;
}

// --------------------------  headings  -------------------------- //


h1,h2,h3,h4,h5,h6 {
	margin: 0;
	font-weight: normal;
}

h1,h2,h3 {
	@include body-text;
}

.heading {
	&.with-margin {
		margin-bottom: $gutter-width;
	}
}

.heading-quiet {
	color: $color-gray;
}

.heading-highlight {
	color: $color-teal;
}

.heading-action {
	color: $color-pink;
}
