// --------------------------  main search overlay  -------------------------- //

.search-overlay {
	@include overlay;
}

.search {
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
}

.search-form {
	@include vertical-center;
	margin: 0 auto;
	width: 80%;
	max-width: 750px;

	padding-left: $gutter-width;
	padding-right: $gutter-width;

	
}

.search-input {
	position: relative;

	&:before {
		content: "";
		width: 29px;
		height: 29px;
		background-image: url('images/search.svg');
		background-position: center;
		background-repeat: no-repeat;
		display: block;
		position: absolute;
		left: -45px;
		top: 50%;
		margin-top: (29px / -2);

		.main-container & {
			background-image: url('images/search-dark.svg');
		}

	}

	a.close {
		right: 0;
		top: 50%;
		margin-top: (29px / -2);
	}

	input.q {
		display: block;
		background-color: transparent;
		border: 0;
		border-bottom: 1px solid #fff;
		@include body-text;
		font-size: 38px;
		color: $color-main;
		width: 100%;
		padding: 10px 0px;

		&::-webkit-input-placeholder,
		&:-moz-placeholder,
		&::-moz-placeholder,
		&:-ms-input-placeholder {
			color: rgba($color-main, 0.95);
		}

		&:focus {
			outline: none;
		}

	}

}

.search-submit {
	display: block;
	position: absolute;
	font-size: 38px;
	background-color: transparent;
	border: 0;
	padding: 0;
	margin: 0;
	padding: 10px 0px;
	left: -999em;

	&:focus {
		left: $gutter-width;
	}

}

// --------------------------  search results  -------------------------- //

.search-results {
	max-width: 890px;
	margin: 0 auto;
	width: 100%;
	padding-top: $header-height;
	margin-top: $gutter-width;

	@include tablet-portrait-and-below {
		padding-top: $header-height-mobile-closed;
	}

	.heading {
		margin-bottom: $gutter-width;
	}

	ul.results {
		@include body-text;

		li {
			margin-bottom: $gutter-width;

			span {
				display: block;
			}

			a {
				&:hover {
					span {
						color: $color-teal;
					}
				}
			}

			.title {

			}

			.url {
				color: $color-gray;
			}

		}

	}

}
