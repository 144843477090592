@include above-tablet-portrait {

	// --------------------------  header  -------------------------- //

	header.header {
		height: $header-height;

		&.visibility-unpinned {
			transform: translateY( ($header-height - $header-height-minimised) *-1 );
		}

		&.visibility-pinned {
			transform: translateY(0px);
		}

	}

	// --------------------------  logo  -------------------------- //

	header.header {

		.header-logo-wrapper {
			width: $logo-width-desktop + ($gutter-width*6);
			background-color: #fff;
			background-image: url('images/logo-division.svg');
			background-repeat: no-repeat;
			background-position: right center;
			height: 100%;
			position: relative;
			background-size: 40px $header-height;
			position: absolute;
			left: 0;
			top: 0;
			&:after {
				content: "";
				display: block;
				position: absolute;
				right: 0;
				background-image: url("images/logo-division.svg");
				background-repeat: no-repeat;
				background-position: center;
				width: 60px;
				height: $header-height-mobile;
				bottom: 0;
			}
		}

		&.visibility-unpinned {

			.header-logo-wrapper {
				display: none;
			}

		}

	}

	a.header-logo {
		width: $logo-width-desktop;
		height: $logo-height-desktop;
		position: absolute;
		left: ($gutter-width*2);
		@include vertical-center;
	}

	// --------------------------  calendar toggle  -------------------------- //

	header.header {

		a.calendar-toggle {
			bottom: $gutter-width;
			left: $logo-width-desktop + ($gutter-width*7.25);
		}

		&.visibility-unpinned {

			a.calendar-toggle {
				left: auto;
				right: $gutter-width*2;
			}

		}

	}

}
	

