@mixin overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	max-width: 100vw;
	max-height: 100vh;
	width: 100%;
	height: 100%;
	z-index: 2000;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	background-color: rgba($color-teal, 0.95);

	opacity: 0;
	display: none;

	&.active {
		opacity: 1;
		display: block;
	}

	a.corner-close {
		z-index: 999;

		@include above-tablet-portrait {
			top: ($gutter-width*2);
			right: ($gutter-width*2);
		}

		@include tablet-portrait-and-below {
			top: $gutter-width-mobile;
			right: $gutter-width-mobile;
		}

	}

}