

.info-bar {
	display: block;
	height: $info-bar-height;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	
	.content-wrapper,
	.actions-wrapper {
		float: left;
		width: 50%;
		height: 100%;
		position: relative;

		@include tablet-landscape-and-below {
			width: 100%;
			float: none;
		}

	}

	.content-wrapper {
		background-color: $color-green;

		@include tablet-landscape-and-below {
			height: 75px;
		}

	}

	.actions-wrapper {

		@include tablet-landscape-and-below {
			height: 50px;
		}

	}

	.content {
		padding-left: ($gutter-width);
		padding-right: 0;
		@include vertical-center;
		height: auto;

		@include tablet-landscape-and-below {
			padding-left: ($gutter-width-mobile);
			padding-right: ($gutter-width-mobile/2);
		}

		.sub {
			@include small-caps;
			margin-bottom: $gutter-width/4;

			a {
				color: #fff;
			}

			@include tablet-landscape-and-below {
				display: inline;
				margin-right: 3px;

				a {
					color: $color-gray;
				}

			}

			.grid-item & {
				a {
					color: $color-gray;
				}
			}

		}

		.heading,
		.dates {
			@extend h1;

			@include tablet-landscape-and-below {
				@include small-caps;
				line-height: 1.3;
			}

			.grid-item & {
				@include small-caps;
				line-height: 1.3;
			}

		}

		.heading {

			@include tablet-landscape-and-below {
				display: inline;
			}
			
			a {
				color: #fff;
			}

			.grid-item & {
				margin-bottom: 3px;
			}

		}

		.dates {
			display: block;

			a {
				color: $color-teal;
			}

		}
		
	}

	.actions-wrapper {
		overflow: hidden;

		@include above-tablet-landscape {

			&:before {
				content: "";
				position: absolute;
				width: 40px;
				height: $info-bar-height;
				background-image: url('images/info-bar-divider.svg');
				display: block;
				top: 0;
				left: 0px;
				z-index: 1000;
			}

		}

		a {
			display: block;
			height: $info-bar-height/2;
			background-color: $color-gray;
			@include small-caps;
			line-height: $info-bar-height/2;
			padding-left: $gutter-width*2;
			transition: background-color $default-duration $default-easing;
			position: relative;

			@include tablet-landscape-and-below {
				width: 50%;
				float: left;
				padding-left: $gutter-width-mobile;
				height: 50px;
				line-height: 50px;
			}

			&:hover {
				background-color: rgba($color-gray, 0.5);
			}

			&:last-child {
				padding-left: $gutter-width*3;

				@include tablet-landscape-and-below {
					padding-left: $gutter-width-mobile*2;

					&:before {
						content: "";
						position: absolute;
						width: 20px;
						height: 60px;
						background-image: url('images/action-bar-button-divider.svg');
						display: block;
						top: 0;
						left: 0px;
						z-index: 1000;
					}

				}

			}

			&.primary {
				background-color: $color-pink;
				color: #fff;

				&:hover {
					background-color: rgba($color-pink, 0.75);
				}

			}

		}

	}

}