.footer {
  background-color: $color-main;
  height: 300px;

  @include tablet-portrait-and-below {
    height: auto;
  }
}

// --------------------------  content  -------------------------- //

.footer-content-wrapper {
  padding: ($gutter-width*2);
  height: 100%;

  @include tablet-portrait-and-below {
    padding: ($gutter-width-mobile);
    height: auto;
  }
}

.footer-content {
  height: 100%;
  color: #fff;

  a {
    color: #fff;
  }
}

// --------------------------  logo  -------------------------- //

$logo-width: 63px;
$logo-height: 76px;

.footer-logo-wrapper {
  width: 15%;
  float: left;
  height: 100%;
  position: relative;
  top: 5px;

  @include tablet-portrait-and-below {
    width: 100%;
    height: auto;
    margin-bottom: $gutter-width-mobile*2;
  }
}

a.footer-logo {
  display: block;
  background-image: url('images/logo-light.svg');
  background-position: center;
  background-repeat: no-repeat;
  width: $logo-width;
  height: $logo-height;
  text-indent: -999em;
  background-size: 100%;
}

// --------------------------  contact  -------------------------- //

.footer-contact {
  width: 40%;
}

// --------------------------  nav  -------------------------- //

.footer-nav,
.footer-nav-secondary {
  width: 15%;
}

// --------------------------  social  -------------------------- //

.footer-social {
  width: 15%;
}

// --------------------------  columns  -------------------------- //

.footer-contact,
.footer-nav,
.footer-social,
.footer-nav-secondary {
  float: left;
  margin-bottom: $gutter-width;

  @include tablet-portrait-and-below {
    float: none;
    width: 100%;
    margin-bottom: $gutter-width-mobile;
  }
}
