.page-columns-wrapper {
	overflow: hidden;
	width: 100%;
	padding-top: ($gutter-width);
	padding-bottom: ($gutter-width * 2);

	&.sub-page {
		margin-top: 60px;
	}

}

.page-columns {
	position: relative;
	max-width: $grid-width;
	margin: 0 auto;
	width: 100%;
}

.page-columns-header,
.page-columns-media,
.page-columns-body {

	@include above-tablet-portrait {
		width: 50%;
	}

}

.page-columns-header {

	.heading,
	.heading a {
		color: $color-teal;
	}

	@include above-tablet-portrait {
		position: absolute;
		top: 0;
		left: 50%;
	}

	@include tablet-portrait-and-below {
		margin-bottom: $gutter-width;
	}

}

.page-columns-media {
	padding-left: $gutter-width;

	.item {
		iframe,
		img {
			display: block;
			border: 0;
		}
	}

	@include above-tablet-portrait {

	}

	@include tablet-portrait-and-below {
		padding-right: $gutter-width;
		margin-bottom: $gutter-width;
	}

}

.page-columns-media,
.page-columns-body {
	float: left;
}

.page-columns-header,
.page-columns-body {
	@include body-text;
	padding-left: $gutter-width;
	padding-right: $gutter-width * 2;
}

.page-columns-body {

	@include above-tablet-portrait {
		top: 0;
		right: 0;
		padding-left: $gutter-width;
		padding-right: $gutter-width * 4;
		padding-top: $gutter-width * 3;
	}

}
