.icon {
	background-repeat: no-repeat;
	display: inline-block;
	background-position: center;
	background-size: 100%;
	width: 17px;
	height: 17px;

	@include tablet-portrait-and-below {
		width: 13px;
		height: 13px;
	}

}

.icon-arrow-down {
	background-image: url('images/arrow-down-green.svg');
}

.icon-plus {
	background-image: url('images/plus-teal.svg');	
}

.icon-minus {
	background-image: url('images/minus-teal.svg');
}