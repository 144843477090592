.infinite-scroll-load {
	text-align: center;
	width: $gutter-width*2;
	height: $gutter-width*2;
	margin: 0 auto;
  margin-top: $gutter-width*2;
	margin-bottom: $gutter-width*2;
	background-image: url('images/spinner.svg');
	background-repeat: no-repeat;
	background-position: center;
	display: none;
	text-indent: -999em;
}
