@mixin image-hover-state {

	@include above-tablet-portrait {

		// Mask the image on hover
		$mask-offset: -94px;

		.image {

			a {

				&:after {
					content: "";
					display: block;
					position: absolute;
					left: 50%;
					background-image: url('images/grid-mask.svg');
					width: 398px;
					height: 300px;
					top: 0;
					opacity: 0;
					margin-left: $mask-offset;
					transform: translateX(20px);
					transition: all $default-duration $default-easing;
				}

			}

		}

		&:hover {

			.image {

				a {
					&:after {
						opacity: 0.5;
						transform: translateX(0);
					}

				}

			}
			
		}

	}

}