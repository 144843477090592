
// --------------------------  primary navigation toggle  -------------------------- //

button.navigation-toggle {
	background-image: url('images/nav-toggle.svg');
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center;
	border: 0;
	width: 17px;
	height: 17px;
	text-indent: -999em;
	position: absolute;
	z-index: 1001;
	cursor: pointer;
	bottom: $gutter-width-mobile;
	right: $gutter-width-mobile;
	outline: none;
	padding: 0;

	header.header.active & {
		background-image: url('images/nav-close.svg');
	}

}


// --------------------------  primary navigation  -------------------------- //

nav.primary,
nav.secondary {
	@include small-caps;
	position: absolute;

	ul {

		li {
			display: block;
			position: relative;
			line-height: 1;

			&:last-child {
				margin-bottom: 0;
			}

			&.social {
				margin-right: $gutter-width/4;
				top: -2px;

				a {
					display: inline-block;
					width: 22px;
					height: 22px;
					background-position: center;
					background-repeat: no-repeat;
					text-indent: -999em;
				}

				&.twitter {
					margin-left: $gutter-width/4;
					a {
						background-image: url('images/twitter.svg');
					}
				}

				&.facebook {
					a {
						background-image: url('images/facebook.svg');
					}
				}

				&.instagram {
					top: -1px;
					a {
						background-image: url('images/instagram.svg');
					}
				}

			}

			a {
				display: inline-block;
				color: #fff;

				&.active {
					border-bottom: 2px solid #fff;
				}

				&.highlight {
					color: $color-pink;
				}

				&:hover {
					color: $color-teal;
					border-color: $color-teal;
				}

			}

		}

	}

}

// --------------------------  secondary navigation  -------------------------- //

nav.secondary {
	position: fixed;
	left: 0;
	right: 0;
	width: 100%;

	ul {

		li {

			a {
				color: $color-main;

				&.active {
					border-bottom: 2px solid $color-main;
				}

			}

		}

	}

}
