.action-bar-wrapper {
	position: absolute;
	bottom: 0;
	z-index: 2000;
	left: 0;
	right: 0;
	width: 100%;

	&.fixed {
		position: fixed;
	}

}

.action-bar {
	
	@include above-tablet-landscape {
		height: $headline-height;
		line-height: $headline-height;
	}

	@include tablet-landscape-and-below {
		
	}

	.info-wrapper,
	.action-wrapper,
	.share-wrapper {
		display: block;
		position: relative;
		@include small-caps;

		@include above-tablet-landscape {
			float: left;
			padding-left: $gutter-width;
			height: 100%;
		}

		@include tablet-landscape-and-below {
			padding-left: $gutter-width-mobile;
			padding-top: $gutter-width-mobile;
			padding-bottom: $gutter-width-mobile;
			padding-right: $gutter-width-mobile;
		}

	}

	.info-wrapper {
		background-color: $color-green;

		.info {
			display: block;
			line-height: $base-line-height;
		}

		@include above-tablet-landscape {
			width: 50%;

			.info {
				@include vertical-center;
			}

		}

		.category,
		.title,
		.dates {
			display: inline;
			margin-right: 5px;
		}

		.category {
			color: $color-gray;
		}

		.title {
			color: #fff;
		}

		.dates {
			color: $color-teal;
		}

	}

	.action-wrapper,
	.share-wrapper {
		color: #fff;
		overflow: hidden;

		@include above-tablet-landscape {

			&:before {
				content: "";
				position: absolute;
				width: 30px;
				height: 90px;
				display: block;
				top: 0;
				left: 0px;
				z-index: 1000;
			}

		}

		@include tablet-landscape-and-below {
			float: left;
		}

	}

	.action-wrapper {
		background-color: $color-pink;

		.action {
			color: #fff;
			line-height: 1;
			display: block;
		}

		@include above-tablet-landscape {
			width: 40%;

			.action {
				@include vertical-center;
				padding-left: $gutter-width;
			}

			&:before {
				background-image: url('images/action-bar-divider-green.svg');
			}

		}

		@include tablet-landscape-and-below {
			width: 75%;
		}
		
	}

	.share-wrapper {
		background-color: $color-teal;

		.share {
			color: #fff;
			line-height: 1;
			display: block;
		}

		@include above-tablet-landscape {
			width: 10%;

			.share {
				@include vertical-center;
				padding-left: $gutter-width;
			}

			&:before {
				background-image: url('images/action-bar-divider-pink.svg');
			}

		}

		@include tablet-landscape-and-below {
			width: 25%;
		}
		
	}


	// If there's no action, adjust widths and divider colour
	&.no-action {

		@include above-tablet-landscape {

			.info-wrapper {
				width: 80%;
			}

			.share-wrapper {
				width: 20%;
				&:before {
					background-image: url('images/action-bar-divider-green.svg');
				}
			}

		}

		@include tablet-landscape-and-below {

			.info-wrapper,
			.share-wrapper {
				width: 100%;
			}

		}

	}

}