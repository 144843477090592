.page-blocks {
	overflow: hidden;
	position: relative;

	&.with-action-bar {
		@include above-tablet-portrait {
			padding-bottom: $headline-height;
		}
		@include tablet-portrait-and-below {
			padding-bottom: 125px;
		}
	}

}

.page-block {

	@include above-tablet-portrait {

		&.half {
			float: left;
			width: 50%;
		}
		&.full {
			width: 100%;
		}

	}

	@include tablet-portrait-and-below {
		width: 100%;
		float: none;
	}

}

// Text block
.page-block.text {

	.heading {
		margin-bottom: $gutter-width;
	}

	.columns {
		overflow: hidden;
		margin-bottom: $gutter-width;
	}

	.column {

		@include above-tablet-portrait {
			float: left;
			width: 50%;
		}

		@include tablet-portrait-and-below {
			float: none;
			width: 100%;
			margin-bottom: $gutter-width-mobile;
		}

		ul, ol {
			list-style: none;
			padding: 0;
		}

	}

	.full-column {

	}

	.with-margin {
		margin-bottom: $gutter-width;
		@include tablet-portrait-and-below {
			margin-bottom: $gutter-width-mobile;
		}
	}

	.content {
		@include body-text;

		@include above-tablet-portrait {
			padding-top: $gutter-width*2;
			padding-bottom: $gutter-width*2;
			padding-left: $gutter-width;
			padding-right: $gutter-width*4;
		}

		@include tablet-portrait-and-below {
			padding-top: $gutter-width-mobile*2;
			padding-bottom: $gutter-width-mobile*2;
			padding-left: $gutter-width-mobile;
			padding-right: $gutter-width-mobile*2;
		}

	}

	.heading {
		color: $color-pink;
	}

	p {

		a {
			border-bottom: 2px solid $color-main;

			&:hover {
				color: $color-teal;
				border-color: $color-teal;
			}

		}

		&:last-child {
			margin-bottom: 0;
		}

	}

	strong {
		font-weight: 900;
	}

	ul,ol {
		padding-left: $gutter-width;
		list-style: initial;
		margin: initial;
		margin-bottom: $gutter-width;
	}

	&.full {

		.content {
			max-width: 750px;
			margin: 0 auto;
			width: 100%;
		}

	}

}



// -- add a margin for first full text block if no hero
.page-blocks {

	&.no-hero {

		.page-block.text.full:first-of-type {

			@include above-tablet-portrait {
				margin-top: 60px;
			}
			@include tablet-portrait-and-below {
				margin-top: 30px;
			}


		}

	}

}



// Image block

$image-height-mobile: 280px;
$image-height: 425px;

.page-block.image {

	@include tablet-portrait-and-below {
		height: $image-height-mobile;

		&.double {
			height: $image-height-mobile*2;
		}

	}

	@include above-tablet-portrait {
		height: $image-height;

		&.double {
			height: $image-height*2;
		}

	}

	.image {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
	}

}

// Video block

$video-height-mobile: 280px;
$video-height: 425px;

.page-block.video {

	@include tablet-portrait-and-below {
		height: $video-height-mobile;
	}

	@include above-tablet-portrait {
		height: $image-height;
	}

	iframe {
		display: block;
		width: 100%;
		height: 100%;
		border: 0;
	}

}


// Quote block
.page-block.quote {

	.content {
		@include body-text;

		@include above-tablet-portrait {
			padding-top: $gutter-width*2;
			padding-bottom: $gutter-width*2;
			padding-left: $gutter-width;
			padding-right: $gutter-width*4;
		}

		@include tablet-portrait-and-below {
			padding-top: $gutter-width-mobile*2;
			padding-bottom: $gutter-width-mobile*2;
			padding-left: $gutter-width-mobile;
			padding-right: $gutter-width-mobile*2;
		}

	}

	.quotation {
		color: $color-pink;
	}

	.attribution {

	}

}
