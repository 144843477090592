a.close {
	width: 26px;
	height: 26px;
	display: block;
	position: absolute;
	
	background-image: url('images/close.svg');
	background-position: center;
	background-repeat: no-repeat;
	text-indent: -999em;
}