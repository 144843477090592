.pagination {
  margin-bottom: $gutter-width;
  margin-top: $gutter-width;
  padding-left: $gutter-width;
  padding-right: $gutter-width;
  @include body-text;
  text-align: center;

  a {
    display: inline-block;
    &:hover {
      color: $color-pink;
    }
    &:first-child {
      margin-right: $gutter-width/2;
    }
  }
}
