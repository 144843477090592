%row {
	width: 100%;
	max-width: $grid-width;
	margin: 0 auto;
	padding-left: $gutter-width/2;
	padding-right: $gutter-width/2;
	position: relative;

	@include tablet-portrait-and-below {
		padding-left: $gutter-width-mobile/2;
		padding-right: $gutter-width-mobile/2;
	}

}
