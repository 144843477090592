.grid-row {

}

.grid-wrapper {
	@extend %row;
	overflow: hidden;
}

.main-container > .grid-wrapper {
	margin-top: $gutter-width;

	&.with-secondary-nav {
		margin-top: $gutter-width + $nav-secondary-height;
	}

	@include tablet-landscape-and-below {
		margin-top: $gutter-width-mobile;

		&.with-secondary-nav {
			margin-top: $gutter-width-mobile + $nav-secondary-height-mobile;
		}
	}

}

// Don't want margin on top if page headline immediately before
.main-container > .page-headline-wrapper + .grid-wrapper {
	margin-top: 0;
}

.grid {

}

.grid-row {
	width: 100%;
	overflow: hidden;

	@include tablet-landscape-and-below {
		display: inline;
	}

	@include mobile-landscape-and-below {
		height: auto;
		display: block;
	}

	@include above-tablet-landscape {
		height: 360px;
		margin-bottom: $gutter-width;
	}

	@include above-tablet-landscape {
		padding-right: 8.5%;
		padding-left: 0;

		&:nth-child(2n) {
			padding-left: 8.5%;
			padding-right: 0;
		}

	}

	&.large {

		@include tablet-landscape-and-below {
			height: auto;
		}

		@include above-tablet-landscape {
			height: 425px;
		}

	}

}

.grid-item-wrapper {
	float: left;
	height: 100%;
	overflow: hidden;

	@include tablet-landscape-and-below {
		height: 330px;
		margin-bottom: $gutter-width-mobile;
	}

	@include above-tablet-landscape {
		height: 100%;
	}

	@include tablet-landscape-and-below {
		padding-left: ($gutter-width-mobile/2);
		padding-right: ($gutter-width-mobile/2);
		width: 50%;
	}

	@include mobile-landscape-and-below {
		padding-left: ($gutter-width-mobile/3);
		padding-right: ($gutter-width-mobile/3);
		width: 100%;
		float: none;
	}

	@include above-tablet-landscape {
		padding-left: ($gutter-width/2);
		padding-right: ($gutter-width/2);

		&.small {
			width: 21%;
		}

		&.medium {
			width: 32%;
		}

		&.large {
			width: 47%;
		}

	}

}

.grid-item {
	background-color: $color-green;
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;

	&.green,
	&.blue,
	&.teal,
	&.pink {
		color: #fff;
	}

	&.green {
		background-color: $color-green;
	}

	&.blue {
		background-color: $color-blue;

		.sub,
		.link {
			color: $color-teal;
			a {
				color: $color-teal;
			}
		}

	}

	&.teal {
		background-color: $color-teal;
	}

	&.pink {
		background-color: $color-pink;
	}

	.content {
		padding: $gutter-width;
	}

	.sub,
	.link {
		@include small-caps;
	}

	.sub {
		margin-bottom: $gutter-width;
	}

	.text {
		@extend h1;
		margin-bottom: $gutter-width;
	}

	// Text grid item
	&.text {

		

	}

	// Image grid item
	&.image {

		.image {
			padding-bottom: 60px;
		}

	}

	// Event grid item, add padding to base of image to account for action
	&.event {

		.image {
			padding-bottom: 125px;
		}

	}

	.image {
		width: 100%;
		height: 100%;
		
		a {
			display: block;
			background-size: cover;
			background-position: center;
			width: 100%;
			height: 100%;
			position: relative;

		}

	}

	// Hover state
	@include image-hover-state;

	.image-link {
		display: block;
		height: 60px;
		line-height: 60px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;

		span.segment {
			display: block;
			width: 50%;
			float: left;
			padding-left: $gutter-width;
			padding-right: 0;
			position: relative;

			span.text {
				@include vertical-center;
				@include small-caps;
			}

			&:first-child {
				background-color: $color-green;
				color: #fff;
			}

			&:last-child {
				background-color: $color-gray;
				color: $color-main;
				padding-left: $gutter-width*2;

				&:before {
					content: "";
					position: absolute;
					width: 20px;
					height: 60px;
					background-image: url('images/info-bar-divider.svg');
					display: block;
					top: 0;
					left: 0px;
					z-index: 1000;
				}

			}

		}

	}

}