// -------------------------- template -------------------------- //

.share-overlay {
	padding-top: 0 !important;
	@include overlay;

	.content {
		height: 100%;
	}

	.share-links {
		@include vertical-center;
		height: 30px;
		text-align: center;
		vertical-align: middle;

		a {
			display: inline-block;
			width: 40px;
			height: 40px;
			background-repeat: no-repeat;
			background-position: center;
			text-indent: -999em;
			margin-left: ($gutter-width/2);
			margin-right: ($gutter-width/2);

			&.facebook {
				background-image: url('images/facebook-large.svg');
				width: 33px;
			}

			&.twitter {
				background-image: url('images/twitter-large.svg');
				width: 35px;
			}

			&.email {
				background-image: url('images/email-large.svg');
				width: 34px;
			}

			&.tumblr {
				background-image: url('images/tumblr-large.svg');
				width: 21px;
			}

		}

	}

}