// --------------------------  box layout  -------------------------- //

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

// --------------------------  global elements  -------------------------- //

html {
  height: 100%;
  display: block;
}

html,
body {
  height: 100% !important;
  padding: 0;
  margin: 0;
}

body {
  background-color: white;
  position: relative;

  &.noscroll {
    overflow: hidden;
    position: fixed;
  }
}

img {
  border: 0;
  display: block;
  width: 100%;
  translate: transformZ(0);
}

// Set the theme colour on the root element
.root {
}

html.js {
  .no-js {
    display: none;
  }
}

/* fade image in after load */
.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 1s $ease-out-quint;
}

// --------------------------  main container -------------------------- //

.main-container-wrapper {
  height: 100%;
  //position: relative;
  z-index: 1;
}

.main-container {
  height: 100%;

  > div:first-child {
    padding-top: $header-height;

    @include tablet-portrait-and-below {
      padding-top: $header-height-mobile-closed;
    }
  }
}
