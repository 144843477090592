@mixin small-caps {
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	line-height: 1;
}

@mixin body-text {
	font-size: 20px;
	line-height: 1.2;

	@include tablet-portrait-and-below {
		font-size: 17px;
		line-height: 1.35;
	}
}