// --------------------------  header  -------------------------- //

header.header {
  background-color: $color-main;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1001;

  html.js & {
    opacity: 1;
    transform-style: preserve-3d;
    transition: all $default-duration $default-easing;

    &.show {
      opacity: 1;
    }
  }
}

// --------------------------  logo  -------------------------- //

header.header {
  .header-logo-wrapper {
    background-color: #fff;
    float: left;
    height: 100%;
    position: relative;

    
  }

  a.header-logo {
    display: block;
    background-image: url("images/logo.svg");
    background-position: center;
    background-repeat: no-repeat;
    text-indent: -999em;
    position: absolute;
    background-size: 100%;
    z-index: 1000;
  }
}

// --------------------------  calendar toggle  -------------------------- //

a.calendar-toggle {
  display: block;
  position: absolute;
  color: #fff;
  @include small-caps;
  color: $color-teal;
  vertical-align: middle;
  line-height: 1;

  &:after {
    @extend .icon;
    @extend .icon-plus;
    content: "";
    vertical-align: middle;
    top: -1px;
    position: relative;
    left: 6px;
  }

  &.active {
    &:after {
      @extend .icon-minus;
    }
  }
}

// --------------------------  logotype  -------------------------- //

$logotype-width: 138px;
$logotype-height: 59px;

header.header {
  .header-logotype-wrapper {
    position: absolute;
    right: ($gutter-width*2);
    height: 100%;
    width: auto;

    @include tablet-landscape-and-below {
      display: none;
    }
  }

  &.visibility-unpinned {
    .header-logotype-wrapper {
      display: none;
    }
  }
}

a.header-logotype {
  display: block;
  background-image: url("images/logotype.svg");
  background-position: center;
  background-repeat: no-repeat;
  text-indent: -999em;
  height: $logotype-height;
  width: $logotype-width;
  @include vertical-center;
}

// --------------------------  visibility  -------------------------- //

header.header {
  &.visibility {
    will-change: transform, opacity;
    animation-fill-mode: both;
  }
}
