.browserupgrade {
	background-color: red;
	position: fixed;
	top: $gutter-width*2;
	left: $gutter-width*2;
	right: $gutter-width*2;
	color: #fff;
	font-size: 18px;
	text-align: center;
	z-index: 2000;
	padding: $gutter-width;

	a {
		text-decoration: underline;
		color: #fff;
	}
	
}