button,
a.button,
input[type='reset'],
input[type='submit'],
input[type='button'],
.Button {
  background-color: transparent;
  border: 1px solid $color-main;
  padding: 12px $gutter-width;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  font-family: 'franklin-gothic-urw', 'Helvetica Neue', 'Helvetica', 'Roboto',
    'Arial', sans-serif;
}

button[disabled],
input[disabled],
select[disabled],
select[disabled] option,
select[disabled] optgroup,
textarea[disabled],
a.button_disabled {
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  color: #888;
  border-color: #888;
  cursor: default;
}

input[type='file'],
input[type='radio'],
input[type='checkbox'] {
  &:focus,
  &:active {
    box-shadow: none;
  }
}

textarea,
select,
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'] {
  border-radius: 0;
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid rgba($color-main, 0.75);
  color: $color-main;
  padding: 12px 16px;
  text-align: left;
  vertical-align: top;
  font-family: 'franklin-gothic-urw', 'Helvetica Neue', 'Helvetica', 'Roboto',
    'Arial', sans-serif;

  &:focus {
    border-color: $color-main;
    outline: none;
  }

  &[disabled] {
    background-color: #eee;
  }
}

select {
  padding-right: 16px*2;
  background-image: url('images/down-pointer.svg');
  background-repeat: no-repeat;
  background-position: right 16px center;
}

// Separate rule for Firefox.
// Separate rule for IE, too.
// Cannot stack with WebKit's.
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: rgba($color-main, 0.5);
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: rgba($color-main, 0.5);
}

input.placeholder_text,
textarea.placeholder_text {
  color: rgba($color-main, 0.5);
}

textarea,
select[size],
select[multiple] {
  height: auto;
}
