
@include above-tablet-portrait {

	// --------------------------  primary navigation toggle  -------------------------- //

	button.navigation-toggle {
		display: none;
	}

	// --------------------------  common navigation  -------------------------- //

	nav.primary,
	nav.secondary {

		ul {

			li {
				line-height: 22px;
				display: inline-block;
				margin-right: ($gutter-width/2);
				margin-bottom: 3px;
			}

		}

	}

	// --------------------------  primary navigation  -------------------------- //

	header.header {

		nav.primary {
			padding-top: $gutter-width;
			width: 75%;
			left: $logo-width-desktop + ($gutter-width*6);
		}

		&.visibility-unpinned {

			nav.primary {
				bottom: 13px;
				left: $gutter-width*2;
			}

		}

	}

	// --------------------------  secondary navigation  -------------------------- //

	nav.secondary {
		top: $header-height;
		z-index: 1000;
		height: $nav-secondary-height;
		line-height: $nav-secondary-height;
		opacity: 1;
		transform-style: preserve-3d;
		transition: all $default-duration $default-easing;

		&.unpinned {
			top: $header-height-minimised;
			opacity: 0;
		}

		ul {
			background-color: #fff;
			position: absolute;
			width: 100%;
			left: $logo-width-desktop + ($gutter-width*6) + 20px;
			padding-left: 25px;

			&:before {
				content: "";
				position: absolute;
				width: 20px;
				height: $nav-secondary-height;
				background-image: url('images/sub-nav-divider.svg');
				display: block;
				top: 0;
				left: -20px;
				z-index: 1000;
			}

			li {

				a {
					&:hover {
						border-color: $color-teal;
					}
				}

			}

		}

	}

}