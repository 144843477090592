@import 'node_modules/flickity/css/flickity.css';

.flickity-prev-next-button {
  border-radius: 0;
  opacity: 0;
  height: 100%;
  transition-delay: $default-duration;
  transition: opacity $default-duration $default-easing;

  &:hover {
    opacity: 0.75;
  }

  &.previous {
    left: 0;
  }

  &.next {
    right: 0;
  }
}
